import styled from 'styled-components';
import { motion } from 'framer-motion';

const Card = styled.div`
  pointer-events: all;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => {
    if (props.noShadow) return 'none';
    if (props.largeShadow) return props.theme.bsSpreadLarge;
    return props.theme.bs;
  }};
  border-radius: ${(props) =>
    props.noCurve ? '0px' : props.theme.borderRadius};
  overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
  margin: ${(props) =>
    props.margin ? props.theme.padding : `${props.theme.padding} 0px`};
  /* margin: ${(props) => props.theme.padding} 0 ${(props) =>
    props.theme.padding} 0; */
  transition: all ${(props) => props.theme.transitions};
  > img {
    transition: all ${(props) => props.theme.transitions};
  }
  &:hover {
    /* transform: ${(props) => (props.hover ? 'scale(1.1, 1.1)' : 'none')}; */
    > img {
      /* transform: ${(props) => (props.hover ? 'scale(1.2, 1.2)' : 'none')}; */
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    border-radius: ${(props) =>
      props.noCurveMobile ? '0px' : props.theme.borderRadius};
  }
`;

const CardSplit = styled(Card)`
  > div:first-child {
    display: inline-block;
    width: 100%;
  }

  > div:nth-child(2) {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-right: -100%;
  }
`;

const CardBody = styled(motion.div)`
  position: relative;
  background-color: ${(props) =>
    props.color ? props.theme.colors.offWhite : props.theme.colors.white};
  padding: ${(props) => {
    if (props.nicePadding) return props.theme.nicePadding;
    if (props.vertPadding) return '3rem 1.6rem';
    return '0px';
  }};
  border-bottom: ${(props) =>
    props.borderBottom ? `1px solid ${props.theme.colors.offWhite}` : 'none'};
  cursor: ${(props) => (props.hover ? 'pointer' : 'auto')};
`;

const CardTopper = styled.div`
  width: 100%;
  height: 6px;
  background: ${(props) => props.theme.colors.gradient};
`;

const CardHeader = styled.div`
  width: 100%;
  height: auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 1rem ${(props) => props.theme.padding};
  background-color: ${(props) =>
    props.offsetColor ? props.theme.offSetColor : props.theme.colors.white};
  p {
    /* color: ${(props) => props.theme.white};
    text-shadow: 1px 1px ${(props) => props.theme.colors.grey}; */
    font-size: 1.2rem;
    color: ${(props) => props.theme.tertiaryText};
    font-weight: bold;
  }
`;

const CardDividerHeader = styled(CardHeader)`
  background-color: ${(props) => props.theme.colors.white};
`;

const CardFooter = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.offWhite};
  display: flex;
  padding: ${(props) => props.theme.padding};
  flex-direction: row;
  /* justify-content: 'flex-end'; */
  justify-content: ${(props) => (props.flex ? props.flex : 'flex-end')};
  /* button {
    margin-left: 16px;
  } */
`;

const StyledGridCard = styled(Card)`
  position: relative;
  display: block;
  flex-direction: column;
  /* width: 300px; */
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 200px;
  cursor: pointer;
  /* border-bottom: 2px solid ${(props) => props.theme.colors.lightestgrey}; */
  transition: all ${(props) => props.theme.transitions};
  &:hover {
    transform: ${(props) => props.theme.hoverScale};
    box-shadow: ${(props) => props.theme.bsSpread};
  }
  > div:first-child {
    width: 100%;
    > img {
      display: inline-block;
      height: 200px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    > div {
      display: inline-block;
      vertical-align: top;
      padding: ${(props) => props.theme.padding};
      p {
        color: ${(props) => props.theme.tertiaryText};
      }
    }
  }
  /* > div:nth-child(2) {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    vertical-align: middle;
  } */
`;

export {
  Card,
  CardBody,
  CardTopper,
  CardFooter,
  CardHeader,
  CardSplit,
  CardDividerHeader,
  StyledGridCard,
};
