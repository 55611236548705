import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import * as analytics from '../analytics/analytics';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FlexBlock, GridBlock, Section, Spacer, Wrapper, StyledSection, GridRowToTopOnTablet, WrapperNarrow } from '../styles/layout';
import { Heading1, Heading3, Heading4, SectionTitle, BodyCopy2, Paragraph, ColorSpan, BodyCopy1, SubTitle, Heading5, HeadingLarge, Heading2 } from '../styles/typography';
import { Button } from '../styles/buttons'
import { Card, CardBody } from '../styles/card'
import IconSchoger from '../ui/icons/IconSchoger'
import bgSwoopUp from '../../static/assets/bg-swoop-up-left.svg';
import bgMiddleSwoop from '../../static/assets/bg-middle-swoop.svg';
import bgMiddleCTA from '../../static/assets/middle-section-cta.svg';
import bgCirclesBlue from '../../static/assets/bg-circles-blue.svg';
import bgFooterWaves from '../../static/assets/bg-footer-waves.svg';

export const pageQuery = graphql`
  query HomeQuery($id: String!){
    selectTaskImg: file(relativePath: { eq: "pick-tasks-iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 380, maxHeight: 700, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
    quickStartImg: file(relativePath: { eq: "quick-start-iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 380, maxHeight: 700, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
    createSessionImg: file(relativePath: { eq: "create-session-iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 380, maxHeight: 700, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
    statsImg: file(relativePath: { eq: "stats-ipad.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, maxHeight: 500, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
    autoShotsImg: file(relativePath: { eq: "automatic-shots.png" }) {
      childImageSharp {
        fluid(maxWidth: 380, maxHeight: 700, quality: 80, srcSetBreakpoints: [960, 1440]) {
          ...GatsbyImageSharpFluid
        }
        sizes {
          src
        }
      }
    }
		markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredText
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 480, maxHeight: 380, quality: 80, srcSetBreakpoints: [960, 1440]) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 380, maxHeight: 480, quality: 80, srcSetBreakpoints: [960, 1440]) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    allCloudinaryMedia {
      edges {
        node {
          secure_url
        }
      }
    }
  }
`

const HomePage = ({ data }) => {

  const login = (position) => {
    analytics.handleLoginClick(position);
    window.location.href = `${process.env.GATSBY_FRONT_END_ADDRESS}/login`
  }

  const signup = (position) => {
    analytics.handleSignUpClick(position);
    window.location.href = `${process.env.GATSBY_FRONT_END_ADDRESS}/signup`
  }

  const { markdownRemark, selectTaskImg, quickStartImg, createSessionImg, statsImg, autoShotsImg, allCloudinaryMedia } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""
  const Image1 = frontmatter.image1 ? frontmatter.image1.childImageSharp.fluid : ""
  const Image2 = selectTaskImg ? selectTaskImg.childImageSharp.fluid : ""
  const Image3 = quickStartImg ? quickStartImg.childImageSharp.fluid : ""
  const Image4 = createSessionImg ? createSessionImg.childImageSharp.fluid : ""
  const Image5 = statsImg ? statsImg.childImageSharp.fluid : ""
  const Image6 = autoShotsImg ? autoShotsImg.childImageSharp.fluid : ""
  const clImages = allCloudinaryMedia.edges
  return (
    <Layout>
      <SEO title="golfBeta | The golf practice app to train smarter and improve faster" description="Designed by golfers, for golfer, golfBeta’s web app combines science with sport to provide a great platform for improving your game." image={Image} />
      <HeroImgContainer>
        {Image ? (
          <Img
            fluid={Image}
            alt={frontmatter.title + ' - Featured image'}
            style={{ position: 'relative !important', height: "100%" }}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "50% 100%",
            }}
          />
        ) : ""}
        <HeroOverlay>
          <Wrapper>
            <GridBlock columns="1fr 1fr" responsive>
              <HeroText direction="column" align="left">
                <HeadingLarge color="offWhite" align="left" margin>
                  Track your practice to improve faster
                </HeadingLarge>
                <Heading3 color="offWhite" margin>
                  Create and play challenging, variable practice sessions with one tap
                </Heading3>
                <Button
                  type="CTA"
                  onClick={() => {
                    signup('landing-top');
                  }}
                >
                  START TRAINING SMARTER FOR FREE
                </Button>

              </HeroText>
              <PhoneImg>
                {Image1 ? (
                  <Img
                    fluid={Image1}
                    alt={`${frontmatter.title} - iPhone`}
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "100% 100%",
                    }}
                  />
                ) : ""}
              </PhoneImg>

            </GridBlock>

          </Wrapper>
        </HeroOverlay>
        <BgSwoop bg={bgSwoopUp} />
      </HeroImgContainer>
      <Section>
        <Wrapper>
          <GridBlock columns="1fr 1fr" responsive>
            <div>
              <Spacer height={10} />
              <Heading2>The golf practice app which makes great practice an easy habit</Heading2>
              <Spacer height={3.2} />
              <Heading3 color="principle">Plan, track and monitor your progress to improve day after day</Heading3>
              <Spacer height={3.2} />
              <BodyCopy1>
                The intelligent golf training platform which automatically builds in the key ingredients for great practice: variability, spacing and challenge
              </BodyCopy1>
              <Spacer height={10} />
            </div>
            {/* <PhoneImg>
              {Image1 ? (
                <Img
                  fluid={Image1}
                  alt={frontmatter.title + ' - iPhone'}
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                />
              ) : ""}
            </PhoneImg> */}

          </GridBlock>

        </Wrapper>
        <Spacer height={10} />
      </Section>

      <MiddleSection bg={bgMiddleSwoop}>
        <WrapperNarrow>
          <Heading1 style={{ textAlign: "center" }}>How can I improve my golf game?</Heading1>
          <Spacer height={10} />
          <FlexBlock direction="column">
            <div
              style={{
                textAlign: 'center',
              }}
            >

              <Card largeShadow>
                <CardBody nicePadding>
                  <Heading3>
                    Consistent, efficient practice
                  </Heading3>
                  <Spacer height={1.6} />
                  <Heading5 color="principle">But most people don't practice in a way which helps them improve</Heading5>
                  <Spacer height={3.2} />
                  <IconSection>
                    <IconSchoger icon="tick" context="success" />
                    <BodyCopy1 color="primary">
                      Creating a plan, ideally with a coach
                    </BodyCopy1>
                    <IconSchoger icon="tick" context="success" />
                    <BodyCopy1 color="primary">
                      Sticking to the plan shot after shot, day after day, week after week
                    </BodyCopy1>
                    <IconSchoger icon="tick" context="success" />
                    <BodyCopy1 color="primary">
                      Practicing with spacing, variability and challenge
                    </BodyCopy1>

                    <IconSchoger icon="tick" context="success" />
                    <BodyCopy1 color="primary">
                      Testing yourself and measuring your results
                    </BodyCopy1>
                  </IconSection>
                  <Spacer height={3} />
                </CardBody>
              </Card>
            </div>
          </FlexBlock>
          <Spacer height={5} />
        </WrapperNarrow>
      </MiddleSection>
      <StyledSection
        id="landing-benefits"
        style={{ backgroundColor: 'white' }}
      >
        <Spacer height={15} />
        <Wrapper>
          <Heading1 style={{ textAlign: 'center' }}>
            There's a smarter way to train
          </Heading1>
          <Spacer height={8} />
          <GridBlock
            columns="1fr 1fr 1fr"
            justify="center"
            responsive
            style={{ textAlign: 'center' }}
          >
            <FlexBlock direction="column" justify="center">
              <IconBubble>
                <IconSchoger icon="collection" />
              </IconBubble>
              <Spacer height={1.6} />
              <Heading3 margin>Train consistently</Heading3>
              <Heading5 color="principle" margin>
                No more scribbled notes and YouTube tips
              </Heading5>
              <Paragraph margin>
                Create intelligent practice sessions which plan out every shot for you automatically
              </Paragraph>
            </FlexBlock>
            <FlexBlock direction="column" justify="center">
              <IconBubble>
                <IconSchoger icon="play" />
              </IconBubble>
              <Spacer height={1.6} />

              <Heading3 margin>Practice with purpose</Heading3>
              <Heading5 color="principle" margin>
                Use golfBeta to track and score every shot
              </Heading5>
              <Paragraph margin>
                We automatically build in variability and spacing to ensure your practice is challenging and engaging
              </Paragraph>
            </FlexBlock>
            <FlexBlock direction="column" justify="center">
              <IconBubble>
                <IconSchoger icon="chart" />
              </IconBubble>
              <Spacer height={1.6} />

              <Heading3 margin>Track improvement</Heading3>
              <Heading5 color="principle" margin>
                Improve your session score over time
              </Heading5>
              <Paragraph margin>
                golfBeta tracks all of your session scores - you can analyse every task and session to stay on track
              </Paragraph>
            </FlexBlock>
          </GridBlock>
        </Wrapper>
        <Spacer height={15} />
      </StyledSection>
      <MiddleSectionCTA bg={bgMiddleCTA}>
        <Spacer height={10} />
        <Wrapper>
          <FlexBlock direction="column" justify="center" style={{ textAlign: 'center' }}>
            <Heading1 color="white">golfBeta is simple, powerful and free</Heading1>
            <Spacer height={3.2} />
            <Heading3 color="principleLight">The best part?  You can get started with an effective training session in one-click</Heading3>
            <Spacer height={3.2} />
            <Button type="CTA">START TRAINING</Button>

          </FlexBlock>

        </Wrapper>
        <Spacer height={10} />
      </MiddleSectionCTA>

      <Section>
        <Spacer height={10} />
        <Wrapper>
          <Heading1 style={{ textAlign: "center" }}>Train smarter, improve faster</Heading1>
          <Spacer height={10} />
          <GridBlock columns="1fr 2fr" align="center" responsive>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <ImgContainer>
                <StyledBGTitled />

                {Image2 ? (
                  <Img
                    fluid={Image2}
                    alt={'Selecting tasks'}

                  />
                ) : ""}
              </ImgContainer>
            </div>

            <div>

              <Heading3 color="principle" margin>
                Simple, repeatable practice time after time
              </Heading3>
              <BodyCopy1 margin>
                It’s hard to work on the same thing week after week. We know that.  Even with best intentions, it’s difficult to stay focused and disciplined shot after shot after shot. There are distractions everywhere with a tonne of YouTube videos and magazine tips.  Instead of solving problems, your practice sessions become the problem.
              </BodyCopy1>
              <BodyCopy1>
                So we created an app to help.  Design tasks which are precisely suited to your needs.  Start a session with one-click with every single shot planned out for you. Just follow the plan, focus on each shot.  Repeat the session at the touch of a button.
              </BodyCopy1>
            </div>
          </GridBlock>
        </Wrapper>
        <Spacer height={10} />
      </Section>
      <Section style={{ overflow: "hidden" }}>
        <Spacer height={10} />
        <Wrapper>
          <GridBlock columns="2fr 1fr" responsive>
            <div>
              <Heading3 color="principle" margin>
                Save time with automatic shot planning
              </Heading3>
              <BodyCopy1 margin>
                The key to better practice is variability, challenge and spacing. It’s how the brain learns.
              </BodyCopy1>
              <BodyCopy1 margin>
                golfBeta automatically creates better, more effective practice sessions — <strong>every shot is planned out, with variability, challenge and spacing baked-in</strong>.
              </BodyCopy1>
              <BodyCopy1>
                Every time.  Without any extra work on your end.
              </BodyCopy1>
              <Spacer height={3.2} />
              <Heading4 color="lightgrey">
                Get started with one-tap!
              </Heading4>
            </div>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <img src={bgCirclesBlue} alt="blue-circles" style={{ position: 'absolute', left: '-100px' }} />
              <ImgContainer>
                {Image6 ? (
                  <Img
                    fluid={Image6}
                    alt={'Quick start session'}

                  />
                ) : ""}
              </ImgContainer>

            </div>
          </GridBlock>
          <Spacer height={15} />

          <GridBlock columns="1fr 2fr" responsive>


            <ImgContainer>

              {Image3 ? (
                <Img
                  fluid={Image3}
                  alt={'Quick start session'}

                />
              ) : ""}
            </ImgContainer>
            <GridRowToTopOnTablet>
              <Heading3 color="principle" margin>
                Practice to fit with your schedule
              </Heading3>
              <BodyCopy1 margin>
                You don’t need to practice from dawn til dusk until your hands bleed.  That’s not how the brain learns.   Short, intense sessions practiced regularly  work can work a treat!  Thank goodness!
              </BodyCopy1>
              <BodyCopy1>
                With golfBeta, you can practice fo 10 minutes or 3 hours…and everything in between.  The intensity will be baked in, to there’s no more excuse - practice short, practice long…just practice!
              </BodyCopy1>
              <Spacer height={10} />
              <Heading3 color="principle" margin>
                One-click quick start
              </Heading3>
              <BodyCopy1 margin>
                We know that you don’t want to be planning out every single session for every time you practice.  That could be a major drag.
              </BodyCopy1>
              <BodyCopy1 margin>
                Well good news!  You don’t have to!  Now you can automatically create a session and get started with just one click!  Tell us which tasks you’re interested in, and we’ll do the rest.
              </BodyCopy1>
              <BodyCopy1>
                It’s never been easier to start a high quality practice session.
              </BodyCopy1>
              <Spacer height={3.2} />
              <Heading4 color="lightgrey">
                Get started with one-tap!
              </Heading4>

            </GridRowToTopOnTablet>
          </GridBlock>
          <Spacer height={15} />
          <GridBlock columns="2fr 1fr" align="center" responsive>

            <div>
              <Heading3 color="principle" margin>
                Totally customisable
              </Heading3>
              <BodyCopy1 margin>
                golfBeta gives you total flexibility to customise every aspect of your practice.  Design your own tasks and session from scratch.  Plan every shot. Create pressure by adding consequences for failing to execute the shot.
              </BodyCopy1>
              <BodyCopy1>
                Work with your coach to build up a library of tasks and sessions which are totally bespoke to you.  You have total control.
              </BodyCopy1>
            </div>
            <ImgContainer>

              {Image4 ? (
                <Img
                  fluid={Image4}
                  alt={'Design a session'}

                />
              ) : ""}
            </ImgContainer>
          </GridBlock>

        </Wrapper>
        <GridBlock gap="3.2rem" columns="75px 75px 75px 75px 75px 75px 75px 75px 75px 75px 75px 75px 75px" style={{ transform: 'rotate(-15deg)', width: '200%', marginLeft: '0px' }}>
          {clImages.map((image, index) => (
            <PictureElement key={`${index}-cl`}>
              <img src={image.node.secure_url} />
            </PictureElement>
          ))
          }
        </GridBlock>
        <Spacer height={15} />
      </Section>

      <Spacer height={5} />
      <SectionGrey>
        <Spacer height={15} />
        <Wrapper>
          <Heading1 style={{ textAlign: "center" }}>Target improvement</Heading1>
          <FlexBlock direction="column" align="center" style={{ textAlign: 'center' }}>
            <Spacer height={10} />
            <Heading3 color="principle">
              View your training statistics and track your results easily so you can keep motivated and target continuous improvement.
            </Heading3>
            <Spacer height={3.2} />
            <Heading4 color="lightgrey">
              No more excuses: golfBeta makes improving easy!
            </Heading4>
            <Spacer height={3.2} />
            <ImgContainerRound>

              {Image5 ? (
                <Img
                  fluid={Image5}
                  alt={'Statistics'}

                />
              ) : ""}
            </ImgContainerRound>
            <Spacer height={10} />
          </FlexBlock>

        </Wrapper>
        <BgSwoopFooter bg={bgFooterWaves} />
      </SectionGrey>
      <Section dark>
        <Wrapper>
          <FlexBlock direction="column" align="center">
            <Spacer height={10} />
            <Heading4 color="white" style={{ textAlign: 'center' }}>
              Get Golfbeta now
            </Heading4>
            <Spacer height={3.2} />
            <Button type="CTA" onClick={() => {
              signup('landing-bottom');
            }}>Join now!</Button>
            <Spacer height={10} />
          </FlexBlock>
        </Wrapper>
      </Section>
    </Layout >
  )
}

export default HomePage

const SectionGrey = styled(Section)`
background-color: ${props => props.theme.colors.offWhite};
`

const Strapline = styled(Heading1)`
position: absolute;
left: 1.6rem;
bottom: 1.6rem;
padding: 1.6rem;
background-color: rgba(0,0,0,0.5);
max-width: 500px;
width: 100%;
border-radius: ${({ theme }) => theme.borderRadius};
box-shadow: ${({ theme }) => theme.bsSpreadLarge};
`

const HeroImgContainer = styled.div`
  height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  height: 900px;
  .gatsby-image-wrapper {
    position: initial;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.tablet}) {
    height: 900px;
    .gatsby-image-wrapper {
    position: initial;
  }
  }
`

const PhoneImg = styled.div`
  width: 100%;
  max-width: 500px;
  justify-self: end;
  margin-top: 100px;
  /* background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MDYiIGhlaWdodD0iNjA2IiB2aWV3Qm94PSIwIDAgNjA2IDYwNiI+CiAgPGNpcmNsZSBpZD0iY2lyY2xlIiBjeD0iMzAzIiBjeT0iMzAzIiByPSIzMDMiIGZpbGw9IiNlYmYxZmYiIG9wYWNpdHk9IjAuNTAyIi8+Cjwvc3ZnPgo=");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;  */
  @media screen and (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    /* margin-top: 0px; */
    justify-self: center;
  }
`

const IconSection = styled.div`
  display: grid;
  grid-template: auto / 24px auto;
  grid-gap: ${(props) => props.theme.padding};
  align-items: center;
  justify-items: self-start;
  p {
    text-align: left;
  }
`;

const IconBubble = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.offWhite};
  align-items: center;
  justify-content: center;
`;

const HeroOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(147.65deg, rgba(245,245,255,0.75) 9.99%, rgba(41, 31, 97, 0.78) 78.07%);
`;

const HeroText = styled(FlexBlock)`
  position: relative;
  margin: 150px 0px 0px 0px;
  z-index: 1;
  text-align: left;
  button {
    margin-top: 20px;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  width: 100%;
`

const ImgContainerInverted = styled(GridRowToTopOnTablet)`
  position: relative;
  width: 100%;
`

const ImgContainerRound = styled(ImgContainer)`
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow:  ${({ theme }) => theme.bsSpreadLarge};
  overflow: hidden;
`

const BgSwoop = styled.div`
position: absolute;
bottom: 0;
width: 100%;

  background-image: url(${props => props.bg});
  height: -webkit-fill-available;
background-position-x: center;
background-position-y: bottom;
background-repeat: no-repeat;
background-size: contain;
`
const BgSwoopFooter = styled(BgSwoop)`
position: relative;
margin-bottom: -2px;
`

const MiddleSection = styled(Section)`
overflow: visible;
background-image: url(${props => props.bg});
height: -webkit-fill-available;
background-position-x: center;
background-position-y: bottom;
background-repeat: no-repeat;
background-size: contain;
height: auto;
`

const MiddleSectionCTA = styled(Section)`
overflow: visible;
background-image: url(${props => props.bg});
height: -webkit-fill-available;
background-position-x: center;
background-position-y: center;
background-repeat: no-repeat;
background-size: cover;
height: 700px;
`

const StyledBGTitled = styled.div`
  position: absolute;
  top: 40px;
  height: 80%;
  width: 270px;
  background-color: rgba(47, 128, 237, 0.61);
  border-radius: 20px;
  transform: rotate(-10deg);
`

const PictureElement = styled.div`
  height: 75px;
  width: 75px;
  filter: saturate(0.3);
  transform: rotate(15deg);
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.bsSpread};
  img {
    height: 75px;
  width: 75px;
  }
`